import { render, staticRenderFns } from "./thirdPartyInfo.vue?vue&type=template&id=d8c9c8ca&scoped=true"
import script from "./index.js?vue&type=script&lang=javascript&external"
export * from "./index.js?vue&type=script&lang=javascript&external"
import style0 from "./index.less?vue&type=style&index=0&id=d8c9c8ca&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8c9c8ca",
  null
  
)

export default component.exports